<template>
    <div class="_gwWrap">
        <div class="_cont">
            <div class="_list" v-show="flag">
                <div class="_li" v-for="(item, index) in allData.data" :key="index" @click="look(item)">
                    <div class="_h4">{{ item.title }}</div>
                    <div class="_time">{{ item.release_date }}</div>
                </div>
            </div>


            <div class="_detail" v-show="!flag">
                <div class="_back">
                    <img @click="back" src="../../../assets/images/back.png" alt="">
                    <div class="_title">{{ detail.title }}</div>
                </div>
                <div class="_html" ref="myHtml" v-html="detail.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDetail } from '../../../api/getData'
export default {
    props: {
        allData: {
            type: Object
        }
    },
    data() {
        return {
            flag: true,
            detail: {},
        }
    },
    mounted() {

    },
    methods: {
        look(item) {
            const params = {
                id: item.id
            }
            getDetail(params).then(res => {
                console.log(res)
                this.detail = res.data
                this.flag = false
                this.$emit("changeCont", false)

                this.$nextTick(() => {
                    // 优化手机端 文档图标

                    const images = this.$el.querySelectorAll('._html img')
                    images.forEach(item => {
                        // console.log(item.src)
                        if (item.src.indexOf('icon_doc.gif') != -1) {
                            item.style.width = 'auto'
                        }
                        if (item.src.indexOf('icon_pdf.gif') != -1) {
                            item.style.width = 'auto'
                        }
                        if (item.src.indexOf('icon_txt.gif') != -1) {
                            item.style.width = 'auto'
                        }
                    })


                    // 优化手机端图片缩进

                    if (window.screen.width <= 768) {
                        const p = this.$el.querySelectorAll('._html p')
                        console.log(p)

                        p.forEach(item => {
                            if (item.outerHTML.indexOf('img') != -1) {
                                item.style.textIndent = 0
                            }
                        })
                    }






                })


            })

        },
        back() {
            this.flag = true
            this.$emit("changeCont", true)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";



._gwWrap
{
    width: 100%;
    background-color: #fff;

    ._cont
    {
        padding: 40px;

        @include respondTo('phone')
        {
            padding: 20px;
        }

        ._list
        {
            ._li
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px dashed #ccc;
                padding: 20px 0;
                cursor: pointer;

                ._h4
                {
                    width: 50%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 20px;

                }

                ._time
                {
                    color: #666;
                    font-size: 20px;
                }
            }
        }

        ._detail
        {
            ._back
            {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
                position: relative;
                align-items: center;

                ._title
                {
                    font-size: 32px;
                }

                img
                {
                    width: 30px;
                    cursor: pointer;
                    position: absolute;
                    left: 0;


                }
            }
        }

    }
}


._title
{
    @include respondTo('phone')
    {
        font-size: 20px !important;
        margin-left: 40px;
    }
}

::v-deep ._html
{
    @include respondTo('phone')
    {
        img
        {
            width: 100%;
        }
    }
}
</style>