<template>
  <div class="_jjWrap">

    <div class="_html" ref="myHtml" v-html="contentJj">

    </div>



  </div>
</template>

<script>
export default {
  props: {
    contentJj: {
      type: String
    }
  },
  data() {
    return {
      timer: null,
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  mounted() {
    let time = 0
    this.timer = setInterval(() => {
      time += 100
      this.$scallHtmlImg()
      if (time >= 3000) {
        clearInterval(this.timer)
      }
    }, 100);
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._jjWrap
{
  background-color: white;

}

._html
{
  width: 1345px;
  min-height: 900px;
  padding: 40px;

}

._html
{
  @include respondTo('phone')
  {
    width: 100%;
    padding: 20px;


  }
}

::v-deep ._html img
{
  @include respondTo('phone')
  {
    width: 100% !important;



  }
}
</style>