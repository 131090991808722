<template>
    <div class="_ldWrap">
        <div class="_list">
            <div class="_li" v-for="(item, index) in allData.data" :key="index">
                <div class="_cont">
                    <img :src="$IMG_URL + item.cover" alt="">
                    <h4>{{ item.name }}</h4>
                    <p>{{ item.position }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        allData: {
            type: Object
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._ldWrap
{
    width: 100%;
    min-height: 600px;
    background-color: #fff;
    overflow: hidden;

}

._list
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 250px;
}

._li
{
    width: 370px;
    height: 240px;
    background: #FFFFFF;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    text-align: center;
    margin: 0 78px;

    ._cont
    {
        transform: translateY(-150px);
    }

    img
    {
        width: 193px;
        height: 242px;
        background: #CF7979;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;


    }

    h4
    {
        font-size: 28px;
        margin: 8px 0 10px;
    }

    p
    {
        padding: 0 8px;
    }
}


._list{
    @include respondTo('phone'){
        display: block;
        margin-top: 180px;
        ._li{
            width: 95%;
            margin: 0 auto;
            height: 260px;
            margin-bottom: 200px;
        }

        ._li:last-child{
            margin-bottom: 40px;
        }
    }
}



</style>