<template>
    <div class="_zzWrap">
        <div class="_cont">
            <div class="_html" ref="myHtml" v-html="allData.content"></div>

            <!-- <img :src="$IMG_URL + allData.cover" alt=""> -->
        </div>

    </div>
</template>

<script>
export default {
    props: {
        allData: {
            type: Object
        }
    },
    data() {
        return {
            content: "",
        }
    },
    destroyed() {
        clearInterval(this.timer)
    },
    created() {
        // // let reg1 = new RegExp("text-wrap: nowrap;", "g")
        // let reg1 = new RegExp("nowrap;", "g")

        // let a = this.allData.content.replace(reg1, 'wrap')
        // console.log(a)
        // this.content = a
    },
    mounted() {
        let time = 0
        this.timer = setInterval(() => {
            time += 100
            this.$scallHtmlImg()
            if (time >= 3000) {
                clearInterval(this.timer)
            }
        }, 100);
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._zzWrap
{
    width: 100%;
    background: #fff;
    min-height: 500px;

    ._cont
    {
        padding: 40px;
        text-align: center;

        @include respondTo('phone')
        {
            padding: 20px;
        }

        ._list
        {
            display: flex;
            flex-wrap: wrap;

            ._li
            {
                width: 312px;
                height: 74px;
                background: #D9D9D9;
                border-radius: 0px 0px 0px 0px;
                // opacity: 0.3;
                text-align: center;
                line-height: 74px;
                margin: 20px 50px;
                color: #000;
                cursor: pointer;

            }
        }
    }
}
</style>