<template>
  <div class="_gybg" ref="_gybgRef" :style="styleObj">
    <div class="_gyAuto">
      <div class="_cont">
        <div class="_left">
          <div :class="{ _li: true, _active: current == index }" v-for="(item, index) in menu" :key="index"
            @click="changeTab(item, index)">{{ item.title
            }}
          </div>
        </div>
        <div class="_right">

          <!--简介  -->
          <transition>
            <div v-if="current == 0">
              <jianjieVue ref="jianjieVueRef" :contentJj="allData.content"></jianjieVue>
            </div>
          </transition>

          <!-- 动态 -->
          <transition>
            <div v-if="current == 1">
              <dongtai ref="dongtaiRef" :allData="allData"></dongtai>
            </div>
          </transition>

          <!-- 领导介绍 -->
          <transition>
            <div v-if="current == 2">
              <ldjs ref="ldjsRef" :allData="allData"></ldjs>
            </div>
          </transition>


          <!-- 组织机构 -->
          <transition>
            <div v-if="current == 3">
              <zzjg ref="zzjgRef" :allData="allData"></zzjg>
            </div>
          </transition>

          <!-- 理事会 -->
          <transition>
            <div v-if="current == 4">
              <lsh ref="lshRef" :allData="allData"></lsh>
            </div>
          </transition>

          <!-- 监事会 -->
          <transition>
            <div v-if="current == 5">
              <jsh ref="jshRef" :allData="allData"></jsh>
            </div>
          </transition>

          <!-- 馆务公开 -->
          <transition>
            <div v-if="current == 6">
              <gwgk ref="gwgkRef" :allData="allData" @changeCont="changeCont"></gwgk>
            </div>
          </transition>

          <!-- 博物馆只有 -->
          <transition>
            <div v-if="current == 7">
              <bwgzy ref="bwgzyRef" :allData="allData"></bwgzy>
            </div>
          </transition>


          <!-- 视频中心 -->
          <transition>
            <div v-if="current == 8">
              <spzx ref="spzxRef" :allData="allData" @changeVideo="changeVideo"></spzx>
            </div>
          </transition>

          <!-- 社会捐赠 -->
          <transition>
            <div v-if="current == 9">
              <shjz ref="shjzRef" :allData="allData"></shjz>
            </div>
          </transition>

          <div class="_page" v-show="pageFlag">
            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
              :limit.sync="queryParams.page_num" @pagination="getList" />
          </div>

          <!-- <div class="_div" v-if="!seen">
            <div class="_more"  @click="nextPage">
            <span class="_x">★</span>
            <span class="_d">★</span>
            <span class="_gd">查看更多</span>
            <span class="_d">★</span>
            <span class="_x">★</span>
          </div>
          </div> -->

          <div class="_bgc"></div>
        </div>
      </div>
    </div>

    <fixIcon />
    <coment ref="comentRef"></coment>
    <mainFooter ref="mainFooterRef"></mainFooter>



    <el-dialog title="" :visible.sync="videoFlag" :width="videoWidth" :before-close="close"
      :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="_videoBox">
        <video ref="myVideo" :src="videoSrc" controls id="videoname" autoplay
          controlslist="nodownload noremoteplayback"></video>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import jianjieVue from './model/jianjie.vue'
import dongtai from './model/dongtai.vue'
import ldjs from './model/ldjs.vue'
import zzjg from './model/zzjg.vue'
import lsh from './model/lsh.vue'
import jsh from './model/jsh.vue'
import Gwgk from './model/gwgk.vue'
import bwgzy from './model/bwgzy.vue'
import spzx from './model/spzx.vue'

import shjz from './model/shjz.vue'


import { getDataById, addHits } from '@/api/getData'
export default {
  components: {
    jianjieVue,
    dongtai,
    ldjs,
    zzjg,
    lsh,
    jsh,
    Gwgk,
    bwgzy,
    spzx,
    shjz
  },
  data() {
    return {
      title: "",
      keywords: "",
      desc: "",
      cate_id: "3",
      obj: {},
      pageFlag: false,
      videoFlag: false,
      videoSrc: "",
      styleObj: {},
      current: 0,
      menu: [
        {
          title: "本馆简介",
          id: 3
        },
        {
          title: "动态",
          id: 4

        },
        {
          title: "领导介绍",
          id: 5

        },
        {
          title: "组织机构",
          id: 6

        },
        {
          title: "理事会",
          id: 7

        },
        {
          title: "监事会",
          id: 8

        },
        {
          title: "馆务公开",
          id: 9

        },
        {
          title: "博物馆之友",
          id: 10

        },
        {
          title: "视频中心",
          id: 11

        },
        {
          title: "社会捐赠",
          id: 12

        },
      ],
      total: 100,
      queryParams: {
        page: 1,
        page_num: 6,
      },

      // 数据
      allData: {},
      seen: true,
      videoWidth: "45%"
    }
  },
  // 动态设置页面标题、关键字和描述等属性  
  // metaInfo() {
  //   return {
  //     title: this.title,
  //     meta: [
  //       { name: "keywords", content: this.keywords },
  //       { name: "description", content: this.desc },
  //     ]
  //   }
  // },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit

      if (title) {
        for (let i in this.obj) {
          if (title == i) {
            this.current = this.obj[i]
            this.cate_id = this.obj[i] + 3
          }
        }
      } else {
        this.current = 0
        this.cate_id = '3'
      }
      if (this.current == 1 || this.current == 6 || this.current == 8) {
        this.pageFlag = true
        this.queryParams.page = 1
      } else {
        this.pageFlag = false
      }

      // console.log(this.cate_id)
      this.getDataById()
      this.$scrollTop()
    }
  },
  async mounted() {
    this.menuSeen()

    await this.getDataById()
    this.$scrollTop()



  },
  created() {
    let obj = {
      '本馆简介': 0,
      '动态': 1,
      '领导介绍': 2,
      '组织机构': 3,
      '理事会': 4,
      '监事会': 5,
      '馆务公开': 6,
      '博物馆之友': 7,
      '视频中心': 8,
      '社会捐赠': 9
    }
    this.obj = obj

    let title = this.$route.query.tit
    if (title) {
      for (let i in obj) {
        if (title == i) {
          this.current = obj[i]
          this.cate_id = obj[i] + 3
        }
      }
    } else {
      this.current = 0
      this.cate_id = '3'
    }


    if (this.current == 1 || this.current == 6 || this.current == 8) {
      this.pageFlag = true
      this.queryParams.page = 1
    } else {
      this.pageFlag = false
    }


  },
  methods: {

    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    changeCont(flag) {
      this.pageFlag = flag
    },

    changeTab(item, index) {

      // console.log(item)
      this.cate_id = item.id
      this.current = index

      if (index == 1 || index == 6 || index == 8) {
        this.pageFlag = true
        this.queryParams.page = 1
      } else {
        this.pageFlag = false
      }
      console.log(item)
      this.$router.push({
        path: "/information",
        query: {
          tit: item.title
        }
      })
      // this.getDataById()

    },
    changeVideo(item) {
      // console.log(item)
      this.videoSrc = this.$IMG_URL + item.video
      const params = {
        id: item.id
      }
      addHits(params).then(res => {

        if (!this.seen) {
          // 移动端
          this.videoWidth = '100%'
        } else {
          this.videoWidth = '45%'
        }
        this.videoFlag = true
        setTimeout(() => {
          this.$refs.myVideo.play();
        }, 50);
      })
    },
    close() {
      this.$refs.myVideo.pause()

      this.videoFlag = false
    },




    async getDataById() {
      const params = {
        cate_id: this.cate_id,
        page: this.queryParams.page,
        page_num: this.queryParams.page_num,
        keywords: "",
        type_id: "",
        times: "",
      }
      await getDataById(params).then(res => {
        // console.log(res)
        this.allData = res.data
        if (res.data.total) {
          this.total = res.data.total

        }
      })
    },
    getList() {
      this.getDataById()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._div
{
  width: 100%;
  background-color: white;
}

._more
{
  color: #AD8D4F;
  cursor: pointer;
  align-items: center;
  height: 36px;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  background: #F3F1EB;

  ._x
  {
    font-size: 12px;
  }

  ._gd
  {
    margin: 0 4px;
  }
}

._page
{
  width: 100%;
  background-color: #fff;
  text-align: center;
}

._bgc
{
  background-color: #fff;
  width: 100%;
  height: 200px;
  background-image: url("../../assets/images/Vector 15.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @include respondTo('phone')
  {
    height: 60px;
  }
}



._gybg
{
  width: 100%;
  background: rgba(248, 245, 238, 1);
  overflow: hidden;

  ._gyAuto
  {
    width: 85%;
    margin: 0 auto;
    margin-top: 200px;


    ._cont
    {
      display: flex;


      ._left
      {
        min-width: 260px;
        width: 260px;
        height: 525px;
        background: #3D4044;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        text-align: center;

        ._li
        {
          color: #F9DAA0;
          width: 100%;
          cursor: pointer;
          padding: 10px 0;
          font-size: 20px;
          border-bottom: 1px dashed rgba(249, 218, 160, .2);
        }

        ._active
        {
          font-weight: bold;
          background-image: url("../../assets/images/Rectangle 266.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        ._active::before
        {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-image: url("../../assets/images/Frame.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 10px;
        }

        ._active::after
        {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-image: url("../../assets/images/Frame.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-left: 10px;
        }
      }

      ._right
      {
        flex: 1;
        margin-left: 20px;
      }
    }
  }
}

._videoBox
{
  width: 100%;

  video
  {
    width: 100%;
  }
}


::v-deep .el-dialog__header
{
  padding: 0;

}

::v-deep .el-dialog__body
{
  padding-top: 40px;
  padding-bottom: 10px;
}

::v-deep .el-dialog__headerbtn
{
  top: 10px;
  right: 10px;

}

::v-deep .el-dialog__headerbtn .el-dialog__close
{
  font-size: 22px;
}


.v-enter-active,
.v-leave-active
{
  transition: all .5s;
}

.v-enter,
.v-leave-to
{
  transform: translateX(100px);
  opacity: 0;
}





._gybg ._gyAuto
{
  @include respondTo('phone')
  {
    margin-top: 60px;
    width: 100%;

    ._cont
    {

      display: block;

      ._left
      {
        width: 100%;
        height: auto;
        border-radius: 0;
        display: flex;
        flex-wrap: wrap;

        ._li
        {
          width: 24%;
          border-bottom: none;
          color: white;
          font-size: 16px;
          text-align: center;
        }

        ._active
        {
          background-image: none;
          color: #F9DAA0;
        }

        ._active::before
        {
          background-image: none;
          content: none;
        }

        ._active::after
        {
          background-image: none;
          content: none;

        }
      }


      ._right
      {
        margin-left: 0;


      }
    }
  }
}
</style>