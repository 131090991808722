<template>
    <div class="_spWrap">
        <div class="_cont">
            <div class="_list">
                <div class="_li" v-for="(item, index) in allData.data" :key="index" @click="lookViode(item)">
                    <div class="_img">
                        <img :src="$IMG_URL + item.cover" alt="" class="_banner">

                        <div class="_div">
                            <img src="../../../assets/images/yx.png" alt="">
                            <span>{{ item.video_time }}</span>
                        </div>

                    </div>

                    <div class="_h4">
                        {{ item.title }}
                    </div>
                    <div class="_p">
                        <span>{{ item.release_date }}</span>
                        <span class="_shu"></span>
                        <span class="_yj">
                            <img src="../../../assets/images/yj.png" alt="">
                            {{ item.hits }}
                        </span>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        allData: {
            type: Object
        }
    },
    data() {
        return {

        }
    },
    methods: {
        lookViode(item) {
            this.$emit('changeVideo', item)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._spWrap
{
    background-color: #fff;
    min-height: 500px;

    ._cont
    {
        padding: 40px;

        ._list
        {
            display: flex;
            flex-wrap: wrap;

            ._li
            {
                // width: 389px;
                width: 30.75%;
                margin: 0 1.29%;
                margin-bottom: 50px;
                cursor: pointer;

                ._img
                {
                    width: 100%;
                    height: 218px;
                    border-radius: 8px 8px 8px 8px;
                    opacity: 1;
                    position: relative;

                    ._banner
                    {
                        width: 100%;
                        height: 100%;
                    }

                    ._div
                    {
                        position: absolute;
                        bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 0 15px;

                        img
                        {
                            width: 24px;
                        }

                        span
                        {
                            color: white;
                        }
                    }
                }

                ._h4
                {
                    width: 100%;
                    color: #333;
                    font-size: 18px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    margin: 10px 0;
                }

                ._p
                {
                    color: #979BA0;
                    display: flex;

                    align-items: center;

                    ._shu
                    {
                        display: inline-block;
                        width: 1px;
                        height: 16px;
                        background-color: #ccc;
                        margin: 0 20px;
                    }
                }
            }
        }
    }
}


._spWrap ._cont
{
    @include respondTo('phone')
    {
        padding: 10px;

        ._list{

            ._li{
                width: 48.9%;
                margin: 0;
                margin-right: 2.1%;
                margin-bottom: 20px;
                ._img{
                    height: 100px;

                    ._banner{
                        object-fit: cover;
                    }
                }

                ._h4{
                    overflow: hidden;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 5px 0;
                }

                ._p{
                    ._shu{
                        margin: 0 10px;
                    }
                    ._yj{
                        display: flex;
                        align-items: center;
                    }
                }
            }

            ._li:nth-child(2n){
                margin-right: 0;
            }
        }
    }
}
</style>