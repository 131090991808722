<template>
    <div class="_dtWrap">
        <div class="_dtcont">
            <div class="_li" v-for="(item, index) in allData.data" :key="index" @click="goOther(item)">
                <div class="_left">
                    <div class="_img">
                        <img :src="$IMG_URL + item.cover" alt="">

                    </div>

                </div>
                <div class="_right">
                    <h4>{{ item.title }}</h4>
                    <div class="_sub">

                        <div class="_time">
                            <span>{{ item.release_date }}</span>
                        </div>

                        <p class="shu"></p>

                        <div class="_hits">
                            <img src="@/assets/images/yj.png" alt="">
                            <span>{{ item.hits }}</span>
                        </div>

                    </div>

                    <div class="_jshao">
                        {{ item.description }}
                    </div>

                    <div class="_xq">
                        <span>查看详情</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { hits } from '@/api/getData'
export default {
    props: {
        allData: {
            type: Object
        }
    },
    methods: {
        goOther(item) {

            if (item.third_link != null && item.third_link != '') {
                const params = {
                    id: item.id
                }
                hits(params)
                window.open(item.third_link)
            } else {
                let url = location.origin
                window.open(`${url}/studyDetail?tit=动态&id=${item.id}`)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._dtWrap
{
    background-color: #fff;

    ._dtcont
    {
        padding: 20px;

        ._li
        {
            display: flex;
            border-bottom: 1px dashed #ccc;
            padding: 30px 0;
            cursor: pointer;

            ._left
            {
                width: 253px;
                height: 159px;

                ._img
                {
                    width: 100%;
                    height: 100%;

                    overflow: hidden;

                    img
                    {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all .5s;
                    }

                    img:hover
                    {
                        transform: scale(1.1);

                    }
                }
            }

            ._right
            {
                flex: 1;
                margin-left: 30px;

                h4
                {
                    font-size: 20px;
                }

                ._sub
                {
                    margin: 5px 0;
                    color: #666666;
                    display: flex;
                    align-items: center;

                    .shu
                    {
                        display: inline-block;
                        width: 1px;
                        height: 12px;
                        background: #CCCCCC;
                        margin: 0 16px;
                    }

                    ._hits
                    {
                        display: flex;
                        align-items: center;

                        img
                        {
                            margin-right: 5px;
                        }
                    }
                }

                ._jshao
                {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                }

                ._xq
                {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 10px;

                    span
                    {
                        display: inline-block;
                        width: 120px;
                        height: 36px;
                        background: #8A2415;
                        border-radius: 0px 0px 0px 0px;
                        opacity: 1;
                        color: white;
                        line-height: 36px;
                        text-align: center;
                    }



                }

            }
        }

    }
}


._dtWrap ._dtcont
{
    @include respondTo('phone')
    {
        ._li
        {
            ._left
            {
                width: 40%;
                height: 100px;
            }

            ._right
            {
                width: 50%;
                margin-left: 10px;

                h4
                {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                ._xq
                {
                    display: none;
                }
            }
        }
    }
}
</style>