<template>
    <div class="_bwgWrap">
        <div class="_cont">
            <div class="_html" ref="myHtml" v-html="allData.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        allData: {
            type: Object
        }
    },
    data() {
        return {
            content: "",
            timer:null,
        }
    },
    destroyed() {
    clearInterval(this.timer)
  },
    created() {
    
    },
    mounted() {



        let time = 0
            this.timer = setInterval(() => {
                time += 100
                this.$scallHtmlImg()
                if(time >= 3000){
                    clearInterval(this.timer)
                }
            }, 100);

    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._bwgWrap
{
    width: 100%;
    background-color: #fff;
    min-height: 500px;

    ._cont
    {
        padding: 40px;
        @include respondTo('phone'){
            padding: 20px;
        }
    }

}
</style>